
import {createSlice} from '@reduxjs/toolkit'

const usersSlice=createSlice({
    name:"users",
    initialState:{
        data:[],
    },
    reducers:{
        loadUsers:(state,action)=>{
            state.data=action.payload.users
        },
        changeActive:(state,action)=>{
            state.data[action.payload.index].activate=action.payload.active
        }
    }
})

export const {changeActive,loadUsers}=usersSlice.actions


export default usersSlice.reducer