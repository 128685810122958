
import {createSlice} from '@reduxjs/toolkit'

const feedSlice=createSlice({
    name:"feedbacks",
    initialState:{
        data:[],
    },
    reducers:{
        loadFeedbacks:(state,action)=>{
            state.data=action.payload.feedbacks
        },
        changeActive:(state,action)=>{
            state.data[action.payload.index].activate=action.payload.active
        }
    }
})

export const {loadFeedbacks}=feedSlice.actions


export default feedSlice.reducer