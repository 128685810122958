import {configureStore} from '@reduxjs/toolkit'
import UsersReducers from "./users";
import BoutiqueReducers from './vendors/stores'
import NotificationReducers from './notificaiton'
import AuthReducer from './authenticate'
import FeedBackReducers from './feedbacks'
import OrderReducers from "./orders";
const store = configureStore({
    reducer:{
        users:UsersReducers,
        notification:NotificationReducers,
        boutiques:BoutiqueReducers,
        authenticate:AuthReducer,
        feedbacks:FeedBackReducers,
        orders:OrderReducers
    },
})



export default store