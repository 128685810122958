import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useSelector, useDispatch } from 'react-redux'
import { off } from '../store/notificaiton'
import MuiAlert from '@material-ui/lab/Alert';
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function SimpleSnackbar(props) {
    const dispatch = useDispatch()
    const noti = useSelector(state => state.notification)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(off())
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: "top", horizontal: "right"
                }}
                open={noti.open} autoHideDuration={2000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={noti.type}>
                    {noti.message}
                </Alert>
            </Snackbar>
        </div>
    );
}


