
import {createSlice} from '@reduxjs/toolkit'

const authSlice=createSlice({
    name:"authenticate",
    initialState:{
        auth:false,
        token:"",
    },
    reducers:{
        authenticate:(state,action)=>{
            state.auth=true
            state.token=action.payload.token
        },
        deAuth: (state,action)=>{
            state.auth=false
            state.token=""
        }
    }
})

export const {authenticate,deAuth}=authSlice.actions
export const authorize=(data)=>{
    return async (dispatch)=>{
        dispatch(authenticate(data))
        localStorage.setItem("@tok",data.token)
        localStorage.setItem("@auth","true")
    }
}
export const getAuth=()=>{
    return async(dispatch)=>{
      const token=await localStorage.getItem("@tok")
      var auth=await localStorage.getItem("@auth")
      if(auth==="true"){
        auth=true
      }else{
         auth=false
      }
      if(auth){
        dispatch(authenticate({token:token}))
      }
    }
}

export const logOut=()=>{
    return async(dispatch)=>{
        localStorage.clear()
        dispatch(deAuth())
    }
}

export default authSlice.reducer