
import {createSlice} from '@reduxjs/toolkit'

const notificaiton=createSlice({
    name:"notification",
    initialState:{
        open:false,
        message:"",
        type:"success"
    },
    reducers:{
        show:(state,action)=>{
            state.open=true
            state.message=action.payload.message
            state.type=action.payload.type
        },
        off:(state,action)=>{
        state.open=false
        }
    }
})

export const {show,off}=notificaiton.actions


export default notificaiton.reducer