import React ,{Suspense,lazy}from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Loader from '../components/loader'
import {useSelector} from 'react-redux'

const Login=lazy(()=>import('../screens/auth/login'))
const Dashboard=lazy(()=>import('../screens/dashboard/main'))

export default function MainRoute() {
  const authenticate=useSelector(state=>state.authenticate)
  React.useEffect(()=>{
   console.log(authenticate)
  },[authenticate])

  return (
    <Router>
        <Suspense fallback={<Loader/>}>
        <Switch>
        {!authenticate.auth?<Route path={"/"}>
            <Login />
          </Route>:
           <Route path="/">
            <Dashboard />
          </Route>}
        </Switch>
        </Suspense>
    </Router>
  );
}