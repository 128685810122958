import './App.css';
import React from 'react'
import theme from './theme'
import { ThemeProvider } from '@material-ui/core/styles'
import MainRoute from './route'
import store from './store'
import { Provider} from 'react-redux'
import {getAuth} from './store/authenticate'
import Notifications from './components/snackbar';
function App() {
  React.useEffect(()=>{
    store.dispatch(getAuth())
  },[])
  return (
    <Provider store={store}>
    <ThemeProvider theme={theme}>
        <div className="App">
        <Notifications />
          <MainRoute />
        </div>
    </ThemeProvider>
    </Provider>
  );
}

export default App;
