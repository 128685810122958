import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main,
    height:"100vh",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
  loading:{
    color:theme.palette.secondary.main,
    fontSize:"1rem",
  }
}));

export default function SimpleBackdrop() {
  const classes = useStyles()
  return (
    <div  className={classes.backdrop}>
         <p className={classes.loading}>Loading....</p>
        <CircularProgress color="secondary" />
      </div>
  );
}
