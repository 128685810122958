import {createSlice} from '@reduxjs/toolkit'

const orderSlice=createSlice({
    name:"orders",
    initialState:{
        data:[],
    },
    reducers:{
        loadOrders:(state,action)=>{
            state.data=action.payload.orders
        },
    }
})

export const {loadOrders}=orderSlice.actions


export default orderSlice.reducer