
import {createSlice} from '@reduxjs/toolkit'

const storesSlice=createSlice({
    name:"boutique",
    initialState:{
        stores:[],
        requests:[]
    },
    reducers:{
        loadStores:(state,action)=>{
            state.stores=action.payload.stores
        },
        loadRequests:(state,action)=>{
            state.requests=action.payload.requests
        },
        changeApprove:(state,action)=>{
            state.stores[action.payload.index].business.approved=action.payload.active
        },
        changeRequest:(state,action)=>{
            state.requests.splice(action.payload.index,1)
        }
    }
})

export const {loadRequests,loadStores,changeApprove,changeRequest}=storesSlice.actions


export default storesSlice.reducer